import React from 'react';
import _padStart from 'lodash/padStart';
import Table from 'ui/elements/Table';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const time = (h, m) => `${_padStart(h, 2, '0')}:${_padStart(m, 2, '0')}`;

const ColumnName = ({
  name
}) => {
  const { t } = useTranslation('conference');

  return t(name);
}

const columns = [
  {
    name: (<ColumnName name='agenda.time'/>),
    key: 'time',
    render: ({ startTime, endTime }) => (
      <strong>
        {time(startTime.hour, startTime.minute)} - {time(endTime.hour, endTime.minute)}
      </strong>
    ),
  },
  {
    name: (<ColumnName name='agenda.description'/>),
    key: 'description',
    render: 'scheduleItem',
    width: 'minmax(max-content, 1fr)',
  },
];

const Agenda = ({
  conference,
}) => {
  const agenda = conference.agenda;

  return (
    <Table
      columns={columns}
      items={agenda}
      color='primary'
    />
  )
};

export default Agenda;
