import React from 'react';
import PropTypes from 'prop-types'
import { Spring, animated } from 'react-spring/renderprops';
import Icon from 'ui/elements/Icon';

import * as S from './styles';

const Description = ({
  children,
  arrowOffset,
  onClose,
}) => {
  return (
    <S.Description arrowOffset={arrowOffset}>
      <S.DescriptionHandlers>
        <S.CloseIconWrapper onClick={onClose}>
          <Icon name='close' />
        </S.CloseIconWrapper>
      </S.DescriptionHandlers>
      <Spring from={{ height: 0 }} to={{ height: 'auto' }}>
        {({ height }) => (
          <animated.div style={{ height, overflow: 'hidden' }}>
            {children}
          </animated.div>
        )}
      </Spring>
    </S.Description>
  )
}

Description.propTypes = {
  arrowOffset: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

Description.defaultProps = {
  arrowOffset: 0,
}

export default Description;
