import React from 'react';
import PropTypes from 'prop-types';
import { Visible, Hidden } from 'react-grid-system';
import Icon from 'ui/elements/Icon';
import Heading from 'ui/elements/Heading';
import _get from 'lodash/get';

import * as S from './styles';

const SidebarContainer = React.forwardRef((props, ref) => {
  const title = props.title || _get(props, 'data.title');
  const iconProps = {
    name: props.icon,
    background: props.palette.variant,
    boxed: true,
    size: 90,
  };

  return (
    <S.Sidebar ref={ref} color={props.palette.accent}>
      <Hidden xs sm>
        <Icon {...iconProps} />
      </Hidden>
      <Visible xs sm>
        <S.IconContainer>
          <Icon {...iconProps} max={90} />
          <Heading level={1} color={props.palette.variant}>
            {title}
          </Heading>
        </S.IconContainer>
      </Visible>
      {props.children}
    </S.Sidebar>
  )
});

SidebarContainer.propTypes = {
  data: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  palette: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default SidebarContainer;
