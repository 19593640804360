import React, { useMemo } from 'react'
import OpticalHeading from 'ui/elements/Heading/OpticalHeading'

import ContentBox from 'widgets/ContentBox';
import Image from 'widgets/Image';
import { buildPersonaData } from 'utils/helpers';

import * as S from './styles';
import { graphql } from 'gatsby'

const Card = React.forwardRef(({
  persona,
  isActive,
  showSummary,
  onClick,
}, ref) => {
  const { fullName, position } = buildPersonaData(persona);
  const image = useMemo(() => {
    if (!persona || !persona.image) {
      return undefined
    }

    if (persona.image.gatsbyImageData) {
      return {
        asset: {
          gatsbyImageData: persona.image.gatsbyImageData,
        },
      }
    }

    return persona.image
  }, [persona])

  return (
    <S.Card ref={ref} onClick={onClick} isActive={isActive}>
      <S.CardHeader>
        {
          image && (
            <Image
              {...image}
              style={{ marginBottom: 10 }}
              alt={fullName}
            />
          )
        }
        <S.CardCaption>
          <OpticalHeading level={4} color='primary'>{fullName}</OpticalHeading>
          <p>{position}</p>
        </S.CardCaption>
      </S.CardHeader>
      {
        showSummary && (
          <ContentBox
            blocks={persona._rawBio}
            truncateLines={4}
          />
        )
      }
    </S.Card>
  )
});

export default Card;

export const query = graphql`
  fragment PersonaFragment on SanityFaculty {
    id
    title
    firstName
    lastName
    _rawBio(resolveReferences: { maxDepth: 4 })
    position
    organization
    expertise
    image {
      gatsbyImageData(
        width: 299
        height: 299
        layout: FIXED
        fit: CROP
        placeholder: DOMINANT_COLOR
      )
    }
  }
`;
