import React from 'react';
import PropTypes from 'prop-types';
import { AvailableColors } from 'ui/elements/ThemeProvider';

import * as S from './styles';

const MeetingActions = ({
  children,
  color,

  inline,
}) => {
  return (
    <S.MeetingActions inline={inline} color={color}>
      {children}
    </S.MeetingActions>
  )
};

MeetingActions.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(AvailableColors),
  inline: PropTypes.bool,
};

export default MeetingActions;
