import React from 'react'
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import Button from 'ui/elements/Button';
import Heading from 'ui/elements/Heading';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import withLocalization from 'decorators/withLocalization';
import ContentBox from 'widgets/ContentBox';
import { Section } from 'components/Layout/styles';
import MeetingLayout from 'components/Layout/MeetingLayout';
import PersonaCarousel from 'components/shared/Persona/PersonaCarousel';

import Agenda from 'modules/Conferences/Agenda';
import Sponsors from 'modules/Conferences/Sponsors';
import Sidebar from 'modules/Conferences/Sidebar';
import { palette, isPastConference } from 'modules/Conferences/utils';

const HeroRegister = ({
  isOverdue,
  href
}) => {
  const { t } = useTranslation('conference')
  if (isOverdue || !href) {
    return null;
  }

  return (
    <Button
      iconName='arrow-right'
      label={t('registerNow')}
      variant='outlined'
      color='inherit'
      href={href}
    />
  );
}

const Conference = ({
  data: rawData,
}) => {
  const { t } = useTranslation('conference');
  const data = _get(rawData, 'sanityConference');
  const isOverdue = isPastConference(data);

  const layoutProps = {
    palette,
    icon: 'conferences',
    data,
  };

  const heroProps = {
    heading: _get(data, 'subtitle.localized'),
    color: palette.accent,
    action: (<HeroRegister isOverdue={isOverdue} href={data?.apply_link} />)
  }

  return (
    <MeetingLayout
      sidebar={Sidebar}
      heroProps={heroProps}
      {...layoutProps}
    >
      <Section>
        <Heading color={palette.variant} level={1}>{data.title}</Heading>
        <ContentBox blocks={data._rawIntro} />
      </Section>

      {
        !!data.speakers.length && (
          <Section fillContainer>
            {
              !isOverdue && (
                <OpticalHeading renderAs="h2" level={4}>
                  {t('thisYearSpeakers')}
                </OpticalHeading>
              )
            }
            <PersonaCarousel data={data.speakers} />
          </Section>
        )
      }

      {
        !isOverdue && data.agenda && data.agenda.length > 0 && (
          <Section background fillContainer padding>
            <OpticalHeading renderAs="h2" level={4}>
              {t('agenda.title')}
            </OpticalHeading>
            <Agenda conference={data} />
          </Section>
        )
      }

      {
        !!data.hosts.length && (
          <Section fillContainer>
            <OpticalHeading renderAs="h2" level={4}>
              {t('hosts')}
            </OpticalHeading>
            <PersonaCarousel data={data.hosts} />
          </Section>
        )
      }

      {
        !!data.sponsorLogos.length && (
          <Section fillContainer>
            <OpticalHeading renderAs="h2" level={4}>
              {t('sponsors')}
            </OpticalHeading>
            <Sponsors sponsorLogos={data.sponsorLogos} />
          </Section>
        )
      }
    </MeetingLayout>
  )
}

export const query = graphql`
  query ReadConference($id: String, $language: String!) {
    sanityConference(id: { eq: $id }) {
      hero {
        ...HeroImage
      }
      sponsorLogos {
        ...SponsorLogo
      }
      subtitle {
        localized
      }
      meta {
        ...MetaFragment
      }
      title
      startDate
      endDate
      fee
      apply_link
      _rawIntro(resolveReferences: { maxDepth: 10 })
      speakers {
        ...PersonaFragment
      }
      language
      location
      hosts {
        ...PersonaFragment
      }
      agenda {
        scheduleItem
        endTime {
          hour
          minute
        }
        startTime {
          hour
          minute
        }
      }
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withLocalization(Conference);
