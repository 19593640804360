import React, { useCallback } from 'react';
import _kebabCase from 'lodash/kebabCase';

import * as S from './styles';

const Row = ({
  columns,
  item,

  rowIndex,
  zebra,
}) => {
  const mapItems = useCallback(() => {
    return columns.map((column) => {
      const data = typeof column.render === 'string' ? item[column.render] : column.render(item);
      const cellId = `cell-${_kebabCase(column.name)}-row-${rowIndex}`;

      const isRowOdd = rowIndex % 2 === 1;

      return (
        <S.Cell key={cellId} id={cellId} zebra={zebra} isRowOdd={isRowOdd}>
          {data}
        </S.Cell>
      );
    });
  }, [item, columns, zebra, rowIndex]);

  return (
    <React.Fragment>
      {mapItems()}
    </React.Fragment>
  )
};


const Body = ({
  items,
  columns,

  zebra,
}) => {
  return items.map((item, i) => (
    <Row
      key={`row-${i}`}
      rowIndex={i + 1}
      columns={columns}
      zebra={zebra}
      item={item}
    />
  ))
}


export default Body;
