import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import Carousel from 'ui/elements/Carousel';

import Card from './Card';

import Description from './Description';
import * as S from './styles';

const PersonaCarousel = ({
  data,
  showSummary,
  descriptionRenderer,

  ...rest
}) => {
  const [active, setActive] = useState(-1);
  const [arrowOffset, setArrowOffset] = useState(0)
  const [baseOffset, setBaseOffset] = useState(0)

  const handleOnClick = (ev, i) => {
    setActive(i);
  };

  /**
   *
   * @type {function(index: number): function(elemRef: HTMLElement|null): undefined}
   */
  const updateArrowOffset = useCallback((index) => (elemRef) => {
    if (!elemRef || active !== index) {
      return
    }

    const elem = elemRef.parentElement

    if (!elem) {
      return
    }

    setArrowOffset(
      elem.offsetLeft + elem.getBoundingClientRect().width / 2
    )
  },[active])

  const onScroll = useCallback((previousSlide, ref) => {
    setBaseOffset(-1 * ref.transform)

    if (active < 0) {
      return
    }

    const minSlide = ref.currentSlide
    const maxSlide = minSlide + ref.slidesToShow

    // update active if value is out of view
    if (active < minSlide) {
      setActive(active + 1)
    }

    if (active >= maxSlide) {
      setActive(active - 1)
    }
  }, [active])

  const hasDescriptionRenderer = useMemo(() => {
    return descriptionRenderer && typeof descriptionRenderer === 'function'
  }, [descriptionRenderer])

  return (
    <S.PersonaCarousel active={active}>
      <Carousel {...rest} afterChange={onScroll} responsive={{ lg: { items: 3 }, xl: { items: 3 } }}>
        {
          data.map((persona, i) => (
            <Card
              key={i}
              onClick={hasDescriptionRenderer ? (ev) => handleOnClick(ev, i) : undefined}
              persona={persona}
              showSummary={showSummary}
              ref={updateArrowOffset(i)}
              isActive={active === i}
            />
          ))
        }
      </Carousel>
      {
        (active !== -1 && hasDescriptionRenderer) && (
          <Description arrowOffset={arrowOffset - baseOffset} onClose={() => setActive(-1)}>
            {descriptionRenderer(data[active])}
          </Description>
        )
      }
    </S.PersonaCarousel>
  )
};

PersonaCarousel.propTypes = {
  descriptionRenderer: PropTypes.func,
}

export default PersonaCarousel;
