import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import useMeasure from 'ui/hooks/useMeasure';
import _get from 'lodash/get';

import Contact from 'components/shared/Contact';

import { HeroPolygon } from '../Hero';
import * as S from './styles';
import SidebarContainer from './SidebarContainer';

const MeetingLayout = ({
  sidebar,
  children,
  heroProps: heroOverride,
  ...layoutProps
}) => {
  const [sidebarRef, sidebarSize] = useMeasure();
  const hasSidebar = sidebar && typeof sidebar === 'function';
  const data = _get(layoutProps, 'data');
  const heroProps = {
    color: layoutProps.palette.variant,
    data,
    ...heroOverride,
  };
  const contact = _get(data, 'contact');

  return (
    <React.Fragment>
      <HeroPolygon {...heroProps} />
      <S.MeetingLayout>
        <Container fluid={true} md={true} sm={true}>
          <Row>
            <Col xs={12} md={3} xl={2} component='aside'>
              <SidebarContainer {...layoutProps} ref={sidebarRef}>
                {
                  hasSidebar && sidebar(layoutProps)
                }
              </SidebarContainer>
            </Col>
            <Col xs={12} md={9} xl={10} component={S.Main} $sidebarSize={sidebarSize}>
              {children}
            </Col>
          </Row>
        </Container>
        {
          contact && (
            <Contact contact={contact} />
          )
        }
      </S.MeetingLayout>
    </React.Fragment>
  );
};

export default MeetingLayout;
