import React  from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import { AvailableColors } from '../ThemeProvider';

import * as S from './styles';

import Body from './Body';
import Head from './Head';

const Table = ({
  columns,
  items,

  color,
  zebra,
  equalColumns,
}) => {
  const columnsDimension = _map(columns, 'width');

  return (
    <S.Table
      columnsDimension={columnsDimension}
      equalColumns={equalColumns}
      color={color}
    >
      <Head columns={columns} />
      <Body zebra={zebra} items={items} columns={columns} />
    </S.Table>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,

  color: PropTypes.oneOf(AvailableColors),
  zebra: PropTypes.bool,
  equalColumns: PropTypes.bool,
};

Table.defaultProps = {
  zebra: true,
};

export { S as TableStyles };
export default Table;
