import styled from 'styled-components';
import { breakpoint } from 'ui/elements/ThemeProvider/utils';
import { Icon } from 'ui/elements/Icon/styles'

export const InfoList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const InfoListItemContent = styled.div`
  font-size: 1.2rem;
  line-height: 1.2;
  flex: 1 1 auto;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 5px;
      
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  
  time {
    display: block;
  }
  
  ${breakpoint.md`
    ul {
      text-align: center;
      flex-direction: column;
      
      li {
        margin-right: 0;
      }
    }
  `}
`;

export const InfoListItemTitle = styled.strong`
  font-size: 1.4rem;
  margin-bottom: 5px;
  white-space: nowrap;

  &:after {
    content: ':';
  }
  
  ${breakpoint.md`
    &:after {
      content: '';
    }
  `}
`;

export const InfoListItem = styled.li`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  
  ${Icon} {
    flex: 0 0 auto;
  }
`;

export const MeetingSummary = styled.div`
  color: ${(props) => props.theme.palette.black};
  
  ${breakpoint.md`
    ${InfoList} {
      display: flex;
      flex-direction: ${(props) => props.inline ? 'row' : 'column'};  
    }
    
    ${InfoListItem} {
      margin: ${(props) => props.inline && '0 20px 0 0'};
      
      &:last-child {
        margin-right: 0;
      }
    }
  `}
  
`;

export const ItemNote = styled.span`
  display: block;
  padding-top: 5px;
`
