import styled, { css } from 'styled-components'
import { breakpoint } from 'ui/elements/ThemeProvider'
import { Heading } from 'ui/elements/Heading/styles'
import { Icon } from 'ui/elements/Icon/styles'

const _Card = styled.div`
  position: relative;
  line-height: 1.6;
  font-weight: 300;
  transition: all 0.5s ease-out;
  margin-bottom: 25px;
  will-change: transform;
`;

export const CardHeader = styled.header`
  font-weight: 400;
  margin-bottom: 10px;
`;

export const CardCaption = styled.div`
  line-height: 1.3;
  font-size: 1.4rem;
  
  ${Heading} {
    text-decoration: underline;
  }
`;

export const DescriptionHandlers = styled.aside`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  
  > * {
    margin: 0 5px;
  }
`

const caretSize = 20;
export const Description = styled.div`
  background: rgb(242, 242, 242);
  position: relative;
  z-index: 30;
  width: 100%;
  margin-bottom: 20px;
  padding: 25px 20px;
  
  &:before {
    display: ${props => props.arrowOffset < 0 ? 'none' : 'block'};
    content: "";

    border-left: ${caretSize}px solid transparent;
    border-right: ${caretSize}px solid transparent;

    border-bottom: ${caretSize}px solid rgb(242, 242, 242);

    position: absolute;
    top: -${caretSize}px;
    left: ${props => props.arrowOffset - (caretSize / 2)}px;
  }

  ${breakpoint.sm`
    padding: 30px 45px;
  `}
`;

export const CloseIconWrapper = styled.span`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  
  ${Icon} {  
    transition: transform 0.3s ease-in-out;
  }
  
  &:hover ${Icon} {
    transform: scale(1.2);
  }
`

export const PersonaGrid = styled.div`
  ${_Card} {
    opacity: ${(props) => props.active !== -1 && 0.4};
  }
`;

export const PersonaCarousel = styled.div`
  ${_Card} {
    opacity: ${(props) => props.active !== -1 && 0.4};
  }
`;

export const Card = styled(_Card)`
  ${props => props.onClick !== undefined && css`
    cursor: pointer;
  `}

  ${(props) => props.isActive && css`
    ${PersonaGrid} &,
    ${PersonaCarousel} & {
      opacity: 1;
      transform: scale(1.02);
    }
  `}
`

