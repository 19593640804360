import React from 'react';
import _kebabCase from 'lodash/kebabCase';

import * as S from './styles';

const Head = ({
  columns,
}) => {
  return (
    <React.Fragment>
      {
        columns.map((column) => (
          <S.TableHeading id={`cell-heading`} key={column.key || _kebabCase(column.name)}>
            {column.name}
          </S.TableHeading>
        ))
      }
    </React.Fragment>
  )
};

export default Head;
