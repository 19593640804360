import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { format } from 'utils/date';

const Date = ({
  date: rawDate,
}) => {
  const { language } = useI18next();

  if (!rawDate) return null;

  const date = format(rawDate, language);

  return (
    <time dateTime={date}>{date}</time>
  )
};

export default Date;
