import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types';
import Icon from 'ui/elements/Icon'

import * as S from './styles';

const IconMap = {
  date: 'calendar',
  location: 'location-pin',
  language: 'speach',
  fees: 'price',
  feeEarlyBird: 'early-bird',
};

const Item = ({
  type,
  value,
  children,
}) => {
  const { t } = useTranslation('common')

  const LabelMap = {
    date: t('date'),
    location: t('location'),
    language: t('language'),
    fees: t('fee'),
    feeEarlyBird: t('feeEarlyBird'),
  };

  const content = Array.isArray(value) ? (
    <ul>
      {value.map((v, i) => (<li key={`item-${type}-${i}`}>{v}</li>))}
    </ul>
  ) : <span>{value}</span>;

  return (
    <S.InfoListItem $type={type}>
      <Icon size={25} name={IconMap[type]} />
      <S.InfoListItemContent>
        <S.InfoListItemTitle>{LabelMap[type]}</S.InfoListItemTitle>
        {content}
        {children}
      </S.InfoListItemContent>
    </S.InfoListItem>
  )
};

Item.propTypes = {
  type: PropTypes.any,
  value: PropTypes.any,
};

const InfoList = ({
  date,
  timezone,
  location,
  language,
  fees,
  feeEarlyBird,
}) => {
  const [t] = useTranslation('common')

  return (
    <S.InfoList>
      {date && (<Item type='date' value={date}>
        {timezone && <S.ItemNote>Dates are in {timezone}</S.ItemNote>}
      </Item>) }
      { location && (<Item type='location' value={location} />) }
      { language && (<Item type='language' value={language} />) }
      { fees !== undefined && (<Item type='fees' value={fees !== 0 ? fees : t('free_of_charge')} />) }
      { feeEarlyBird && (<Item type='feeEarlyBird' value={feeEarlyBird} />) }
    </S.InfoList>
  )
};

InfoList.propTypes = {
  date: PropTypes.any,
  location: PropTypes.any,
  language: PropTypes.any,
  fees: PropTypes.any,
};

export default InfoList;
