import styled from 'styled-components';

export const MeetingActions = styled.div`
  display: flex;
  flex-direction: ${(props) => props.inline ? 'row' : 'column'};
  color: ${(props) => props.color ? props.theme.palette[props.color] : 'inherit'};
  justify-content: space-between;
  
  > * {
    margin: ${(props) => props.inline ? '0 10px 0 0' : '0 0 10px'};
    
    &:last-child {
      margin: 0;
    }
  }
`;
