import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui/elements/Button';
import { useTranslation } from 'gatsby-plugin-react-i18next';

//import { Date, MeetingActions, MeetingSummary } from 'components/shared';
import Date from 'components/shared/Date'
import MeetingActions from 'components/shared/MeetingActions'
import MeetingSummary from 'components/shared/MeetingSummary'
import { palette, isPastConference } from 'modules/Conferences/utils';

const LangTextMap = {
  en: 'english',
  de: 'german'
};

const Sidebar = ({
  data,
}) => {
  const { t } = useTranslation('conference');
  const isOverdue = isPastConference(data);
  const href = data?.apply_link;

  return (
    <React.Fragment>
      {!isOverdue && href && <MeetingActions>
        <Button
          color={palette.accent}
          fill
          label={t('registerNow')}
          iconName='arrow-right'
          href={href}
        />
      </MeetingActions>}
      <MeetingSummary
        date={[
          <Date date={data.startDate} />,
          '-',
          <Date date={data.endDate} />,
        ]}
        location={data.location}
        language={t(`common:${LangTextMap[data.language]}`)}
        fees={data.fee}
      />
    </React.Fragment>
  )
};

Sidebar.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Sidebar;
