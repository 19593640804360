import React from 'react';
import Carousel from 'ui/elements/Carousel';

import Image from 'widgets/Image';
import { SponsorLogoFragment } from '../../generated/graphql-operations'
import { graphql } from 'gatsby'

const Sponsors: React.FC<{
  sponsors: SponsorLogoFragment[]
}> = ({
  sponsors,
}) => {
  return (
    // @ts-ignore
    <Carousel responsive={{
      xs: { items: 2 },
      sm: { items: 2 },
      md: { items: 3 },
      lg: { items: 4 },
      xl: { items: 4 },
    }}>
      {
        sponsors.map((sponsor, i) => (
          <Image
            {...sponsor}
            alt={`Sponsor ${i}`}
            key={`sponsor-${sponsor.id}`}
          />
        ))
      }
    </Carousel>
  )
};

export default Sponsors;

export const query = graphql`
    fragment SponsorLogo on SanityImage {
      id: _key
      gatsbyImageData(
        layout: CONSTRAINED
        width: 250
      )
    }
`
