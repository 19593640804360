import React from 'react';
import PropTypes from 'prop-types';
import { AvailableColors } from 'ui/elements/ThemeProvider';

import * as S from './styles';

import InfoList from './InfoList';

const MeetingSummary = ({
  date,
  timezone,
  location,
  language,
  fees,
  feeEarlyBird,

  inline,
  color,
}) => {
  return (
    <S.MeetingSummary inline={inline} color={color}>
      <InfoList
        date={date}
        timezone={timezone}
        location={location}
        language={language}
        fees={fees}
        feeEarlyBird={feeEarlyBird}
      />
    </S.MeetingSummary>
  )
};

MeetingSummary.propTypes = {
  date: PropTypes.any,
  location: PropTypes.any,
  language: PropTypes.any,
  fees: PropTypes.any,
  feeEarlyBird: PropTypes.any,

  inline: PropTypes.bool,
  color: PropTypes.oneOf(AvailableColors),
};

export default MeetingSummary;
